import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { Input, Icon, Transition } from 'semantic-ui-react';
import CartContext from '../Context/CartContext';

import Cart from '../../services/cart';

const AddToCart = product => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [visible, setVisible] = useState(false);
  const { addToCart } = useContext(CartContext);

  const toggleMessage = () => {
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  const validate = quantity => {
    let error;
    const re = /^[0-9\b]+$/;

    if (!quantity) error = "Can't be blank";
    if (!re.test(quantity)) error = 'Please enter an integer for the quantity';

    return error;
  };

  const handleSubmit = async () => {
    const cartId = await localStorage.getItem('mcart');
    const error = validate(quantity);
    setError(error);

    if (!error) {
      setLoading(true);
      // adiciona item ao carrinho
      Cart.add({ ...product, quantity })
        .then(data => {
          addToCart(quantity, cartId);
          setLoading(false);
          setQuantity(quantity);
          setVisible(true);
          toggleMessage();
        })
        .catch(e => {
          const data = e.response.data;

          setLoading(false);
          if (data.status_code === 422) {
            const { errors } = data;
            if (errors.quantity) {
              setError(errors.quantity[0]);
            } else {
              setError('Houve um erro ao add.');
            }

            return false;
          }

          setError();
          navigate('/login');
        });
    }
  };

  const handleChange = ({ target: { value } }) =>
    setQuantity(parseInt(value, 10));

  return (
    <>
      <Input
        type="number"
        placeholder="Quantidade"
        value={quantity}
        min={1}
        step={1}
        error={!!error}
        onChange={handleChange}
        style={{ width: '25%' }}
        action={{
          color: 'orange',
          content: 'Adicionar ao Carrinho',
          icon: 'plus cart',
          onClick: handleSubmit,
          loading,
          disabled: loading,
        }}
      />

      {error && (
        <div style={{ color: 'red', position: 'absolute' }}>{error}</div>
      )}
      <Transition duration={{ hide: 500, show: 500 }} visible={visible}>
        <div style={{ color: 'green', position: 'absolute' }}>
          <Icon name="check" />
          Item Adicionado
        </div>
      </Transition>
    </>
  );
};

export default AddToCart;
