import React from 'react';
import { Grid, Header, Image, Statistic } from 'semantic-ui-react';
import parser from 'html-react-parser';

const Mobile = ({
  name,
  default_photo,
  price,
  description,
  quantity_min,
  quantity_max,
}) => {
  const descriptionFormat = parser(`${description}`);

  return (
    <Grid columns={6}>
      <Grid.Row>
        <Grid.Column width={6} verticalAlign="middle" textAlign="center">
          <Image
            src={default_photo}
            alt={name}
            style={{ maxWidth: '100px', margin: '0 auto' }}
            circular
          />
        </Grid.Column>
        <Grid.Column width={10} verticalAlign="top" textAlign="left">
          <Header as="h3">{name}</Header>
          {descriptionFormat}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="pt0">
        <Grid.Column width={16} textAlign="center">
          <Statistic size="mini">
            <Statistic.Value style={{ fontSize: '10px' }}>
              R${price}
            </Statistic.Value>
            <Statistic.Label style={{ fontSize: '10px' }}>
              a peça
            </Statistic.Label>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Value style={{ fontSize: '10px' }}>
              {quantity_min}
            </Statistic.Value>
            <Statistic.Label style={{ fontSize: '10px' }}>
              Qtd. Mínima
            </Statistic.Label>
          </Statistic>
          <Statistic size="mini">
            <Statistic.Value style={{ fontSize: '10px' }}>
              {quantity_max}
            </Statistic.Value>
            <Statistic.Label style={{ fontSize: '10px' }}>
              Qtd. Máxima
            </Statistic.Label>
          </Statistic>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default Mobile;
