import React from 'react';
// import Img from 'gatsby-image'
// import parser from 'html-react-parser';
import { Responsive } from 'semantic-ui-react';
import Desktop from './Desktop';
import Mobile from './Mobile';

export default ({
  name,
  default_photo,
  price,
  description,
  quantity_min,
  quantity_max,
}) => (
  <>
    <Responsive maxWidth={768}>
      <Mobile
        name={name}
        default_photo={default_photo}
        price={price}
        description={description}
        quantity_min={quantity_min}
        quantity_max={quantity_max}
      />
    </Responsive>
    <Responsive minWidth={769}>
      <Desktop
        name={name}
        default_photo={default_photo}
        price={price}
        description={description}
        quantity_min={quantity_min}
        quantity_max={quantity_max}
      />
    </Responsive>
  </>
);
