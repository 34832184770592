import http from '../plugins/http';
import { getData } from '../utils/get';

const Cart = {
  add: ({ id, name, quantity, price, ...attributes }) =>
    http
      .put('/api/cart', { id, name, quantity, price, ...attributes })
      .then(getData),

  items: () => http.get(`/api/cart`).then(getData),

  update: (id, { relative, quantity, price }) =>
    http.post(`/api/cart/${id}`, { relative, quantity, price }).then(getData),

  remove: id => http.delete(`/api/cart/${id}`).then(getData),

  clear: () => http.post(`/api/cart/clear`).then(getData),
};

export default Cart;
