import React from 'react';
import { Divider, Grid, Header, Image, Statistic } from 'semantic-ui-react';
import parser from 'html-react-parser';

const Desktop = ({
  name,
  default_photo,
  price,
  description,
  quantity_min,
  quantity_max,
}) => (
  <>
    <Grid columns={16}>
      <Grid.Column width={6} verticalAlign="middle" textAlign="center">
        <Image
          src={default_photo}
          alt={name}
          style={{ maxWidth: '150px', margin: '0 auto' }}
          circular
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Header as="h2">{name}</Header>

        <Divider
          hidden
          style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}
        />

        {parser(`${description}`)}

        <Divider
          hidden
          style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}
        />

        <Statistic size="mini" horizontal>
          <Statistic.Value style={{ fontSize: '10px' }}>
            R${price}
          </Statistic.Value>
          <Statistic.Label style={{ fontSize: '10px' }}>a peça</Statistic.Label>
        </Statistic>
        <Statistic size="mini" horizontal>
          <Statistic.Value style={{ fontSize: '10px' }}>
            {quantity_min}
          </Statistic.Value>
          <Statistic.Label style={{ fontSize: '10px' }}>
            Qtd. Mínima
          </Statistic.Label>
        </Statistic>
        <Statistic size="mini" horizontal>
          <Statistic.Value style={{ fontSize: '10px' }}>
            {quantity_max}
          </Statistic.Value>
          <Statistic.Label style={{ fontSize: '10px' }}>
            Qtd. Máxima
          </Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid>
  </>
);

export default Desktop;
