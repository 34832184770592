import React from 'react';
// import Layout from '../components/Layout'
// import { Link } from 'gatsby'
import Img from './Image';
import { Card } from 'semantic-ui-react';

export default ({ products }) => {
  const productsList = products
    .filter((p, i) => p.actived === true && i > 0)
    .map(product => ({
      color: 'blue',
      key: product.id,
      image: (
        <Img {...product} />
        // <Image onClick={onClick} src={photo.image} wrapped  />
      ),
    }));

  return <Card.Group items={productsList} itemsPerRow={3} doubling />;
};

// export default PackagePhoto
