/* eslint-disable */
import React, { createRef } from 'react';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { Divider, Ref } from 'semantic-ui-react';
import SEO from '../components/SEO';
import PackageSummary from '../components/PackageSummary';
import PackageDescription from '../components/PackageDescription';
// import PackageAlbums from '../components/PackageAlbums'
import ProductPhotos from '../components/PackageProduct';
// import ProductAttributes from '../components/ProductAttributes'
import Layout from '../components/Layout';

class PackagePage extends React.PureComponent {
  render() {
    const packageInfo = _get(this, 'props.data.laravel.package');
    const packageData = {
      ...packageInfo,
      id: packageInfo.id,
      name: packageInfo.name,
      refer: packageInfo.ref,
      default_photo: packageInfo.default_photo,
      description: packageInfo.description,
      price: packageInfo.price,
      quantity_min: packageInfo.quantity_min,
      quantity_max: packageInfo.quantity_max,
      products: packageInfo.products,
    };

    let productsData = [];

    _forEach(packageInfo.products, product => {
      productsData.push({
        ...product,
        description:
          product.description !== null
            ? product.description
            : packageData.description,
        quantity: 1,
      });
    });

    return (
      <Layout location={this.props.location}>
        <SEO title={packageInfo.name} />

        <PackageSummary {...packageData} ref={this.createRef} />

        <Divider />
        <ProductPhotos products={productsData} />
      </Layout>
    );
  }
}

export default PackagePage;

export const pageQuery = graphql`
  query PackageQuery($ref: String!) {
    laravel {
      package(ref: $ref) {
        id
        ref
        name
        default_photo
        description
        price
        quantity_min
        quantity_max
        products {
          id
          name
          price
          image
          description
          actived
        }
      }
    }
  }
`;
