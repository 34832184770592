import React from 'react';
import { Image as Img, Modal, Header, Divider } from 'semantic-ui-react';
import parser from 'html-react-parser';
import AddToCard from '../AddToCart';

const ImageAlbum = product => {
  let { image } = product;
  const src = <Img src={image} wrapped />;

  return (
    <Modal trigger={src} closeIcon>
      {/* <Modal.Header>{product.name}</Modal.Header> */}
      <Modal.Content image>
        <Img size="large" src={image} />
        <Modal.Description>
          <Header>{product.name}</Header>
          {parser(`${product.description}`)}

          <Header as="h4">Preço: R$ {product.price}</Header>
          <Divider hidden />
          <AddToCard {...product}></AddToCard>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ImageAlbum;
